<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Games from "@/services/Games";
import ColumnVisibilityV2 from '@/components/ColumnVisibilityV2';

export default {
  components: { Layout, PageHeader, ColumnVisibilityV2 },
  page: {
    title: "Leaderboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Leaderboard",
      items: [
        {
          text: "Fan Experience",
          href: "/",
        },
        {
          text: "Leaderboard",
          active: true,
        },
      ],
      showLoader:false,
      showLoader_init:false,
      leaderboardFullData: [],
      leaderboardType: '',
      leaderboardGame: "",
      selectedGameId: "", // Store selected game_id
      uniqueGameIds: [], // Store unique game IDs here
      selectedType: "", // Store selected type
      availableTypes: [], // Store available types for selected game_id
      leaderboard_id: "",
      availableSessions: [], // Store available sessions
      availableMonths: [], // Store available months
      selectedTimePeriod: "", // Store selected time period
      leaderboardData: [],
      leaderboardTop3: [],
      matches: [],
      selectedMatch: '',
      isBusy: false,
      showMatchSelection: false,
      showLeaderTable: false,
      showFullTable: false,
      error: null,
      totalPages: 0,
      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      filter: '',
      sortBy: "position",
      sortDesc: 'false',
      fields: [
        {
          value: "position",
          text: "Position",
          sortable: true,
          visible: true
        },
        {
          value: "score",
          text: "Score",
          sortable: true,
          visible: true
        },
        {
          value: "first_name",
          text: "Customer",
          sortable: true,
          visible: true
        },
        {
          value: "nickname",
          text: "Nickname",
          sortable: true,
          visible: true
        },
      ]
    };
  },

  middleware: "authentication",
  mounted() {
    this.getLeaderboardMiniSetup()
  },
  computed: {
    getTopDisplayName() {
      return (index) => {
        const item = this.leaderboardTop3[index];
        return item.first_name ? `${item.first_name} ${item.middle_name} ${item.last_name}` : item.email;
      };
    }
  },

  methods: {
    async onTimePeriodChange() {

      if(this.selectedTimePeriod){

        await this.getLeaderboardData();
        //await this.getTopLeaderboardData()
        this.showLeaderTable = true
        this.showFullTable = true
      }
    },

    async onGameIdChange() {
      try {
        this.showLeaderTable = false
        this.showLoader_init = true;

        // Fetch leaderboard data for the selected game_id
        const response = await Games.getLeaderboardMiniSetup(this.selectedGameId);
        this.leaderboardFullData = response.data;

        // Extract available types
        if (this.leaderboardFullData && Array.isArray(this.leaderboardFullData.data)) {
          const uniqueTypesSet = new Set();
          this.leaderboardFullData.data.forEach(item => {
            if (item.type) {
              uniqueTypesSet.add(item.type);
            }
          });

          this.availableTypes = Array.from(uniqueTypesSet);

          if (this.availableTypes.length > 0) {
            this.selectedType = this.availableTypes[0];
            this.onTypeChange();  // Call onTypeChange to update time periods
          } else {
            this.selectedType = "";
          }
        } else {
          this.availableTypes = [];
          this.selectedType = "";
        }

      } catch (error) {
        console.error("Error fetching types:", error);
        this.error = error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : "An unexpected error occurred.";
      } finally {
        this.showLoader_init = false;
      }
    },

    onTypeChange() {
      this.showLeaderTable = false

      if (this.leaderboardFullData && Array.isArray(this.leaderboardFullData.data)) {
        const sessions = [];
        const months = [];

        this.leaderboardFullData.data.forEach(item => {
          if (item.type === 'season') {
            sessions.push(item.type_id);
          } else if (item.type === 'month') {
            months.push(item.type_id);
          }
        });

        if(months.length){
          months.sort((a, b) => {
            // Split the year and month components
            let [yearA, monthA] = a.split('-').map(Number);
            let [yearB, monthB] = b.split('-').map(Number);

            // Compare year first, then month
            if (yearA !== yearB) {
              return yearB - yearA; // Reverse order for years
            } else {
              return monthB - monthA; // Reverse order for months
            }
          });
        }

        this.availableSessions = Array.from(new Set(sessions));
        this.availableMonths = Array.from(new Set(months));

      }  else {
        console.log("No valid leaderboardFullData or data array is not an array.");
        this.availableSessions = [];
        this.availableMonths = [];
      }

      this.selectedTimePeriod = ""; // Reset selected time period
    },

    async getLeaderboardMiniSetup() {
      try {
        const filterData = this.setPaginationFilters();
        const response = await Games.getLeaderboardMiniSetup(filterData);
        const leaderboardData = response.data

        if (leaderboardData && Array.isArray(leaderboardData.data) && leaderboardData.data.length > 0) {
          const gameIds = leaderboardData.data.map(item => item.game_id);
          this.uniqueGameIds = [...new Set(gameIds)];

         /* if (!this.leaderboardGame && this.uniqueGameIds.length > 0) {
            this.leaderboardGame = this.uniqueGameIds[0];
          }*/
        } else {
          console.log("No data found in response", leaderboardData);
        }
      } catch (error) {
        console.error("Error fetching leaderboard data:", error)
        this.error = error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : "An unexpected error occurred."
      }
    },

    async getLeaderboardData() {
      try {
        this.totalPages = 0
        this.totalItems = 0
        this.showLoader_init = true;
        this.showLoader = true;

        if (this.selectedGameId === '' || this.selectedType === '') {
          this.showLoader_init = false;
          this.showLoader = false;
          return false;
        }

        let data = `type=${this.selectedType}&game_id=${this.selectedGameId}&type_id=${this.selectedTimePeriod}`;
        let filterData = this.setPaginationFilters(data);

        const response = await Games.getLeaderboardMini(filterData);
        var leaderboardTableData = response.data;

        if (leaderboardTableData.leaderboard && leaderboardTableData.leaderboard.length > 0) {
          this.leaderboardData = leaderboardTableData.leaderboard;
          this.totalItems = leaderboardTableData.total_items;
          this.totalPages = leaderboardTableData.total_pages;

          // Update top 3 leaderboard
          this.leaderboardTop3 = leaderboardTableData.leaderboard.slice(0, 3);
        } else {
          this.leaderboardTop3 = [];
          this.leaderboardData = [];
        }

        this.showLoader_init = false;
        this.showLoader = false;
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        this.error = error.response && error.response.data && error.response.data.error
            ? error.response.data.error
            : "An unexpected error occurred.";
        this.showLoader_init = false;
        this.showLoader = false;
      }
    },

    formatNickname(nickname){
      return nickname ? nickname : 'N/A'
    },

    handlePaginationChange(){
      if(this.selectedType == 'season'){
        this.getLeaderboardData('season')
      }else if(this.selectedType == 'month'){
        this.getLeaderboardData('match', this.selectedMatch)
      }
    },

    leaderboardTitle(){
      if(this.selectedType == 'season'){
        return 'Season Leaderboard <span>'+this.selectedTimePeriod+'</span>'
      }else if(this.selectedType == 'month') {
        return 'Monthly Leaderboard <span>'+this.selectedTimePeriod+'</span>'
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="row m-2">
            <div class="col-4 col-md-4 mx-auto">
              <label class="col-md-12 text-center col-form-label">Select Game</label>
              <select class="form-select" @change="onGameIdChange" v-model="selectedGameId">
                <option v-for="gameId in uniqueGameIds" :key="gameId" :value="gameId">{{ gameId }}</option>
              </select>
            </div>
            <div class="col-4 col-md-4 mx-auto">
              <label class="col-md-12 text-center col-form-label">Select Type</label>
              <select class="form-select" @change="onTypeChange" v-model="selectedType">
                <option v-for="type in availableTypes" :key="type" :value="type">{{ type }}</option>
              </select>
            </div>
            <div class="col-4 col-md-4 mx-auto mb-4">
              <label class="col-md-12 text-center col-form-label">Select Period</label>
              <select class="form-select" @change="onTimePeriodChange" v-model="selectedTimePeriod">
                <template v-if="selectedType === 'season'">
                  <option v-for="session in availableSessions" :key="session" :value="session">{{ session }}</option>
                </template>
                <template v-if="selectedType === 'month'">
                  <option v-for="month in availableMonths" :key="month" :value="month">{{ month }}</option>
                </template>
              </select>
            </div>
            <div  v-if="!showLoader_init">
            </div>
            <div v-else>
              <div class="card-body d-flex align-items-center justify-content-center">
                <b-spinner large></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card" v-if="showLeaderTable">
          <h4 class="text-center mt-4" v-html="leaderboardTitle()"></h4>
          <div class="card-body" v-if="!showLoader_init">
            <div class="row" v-if="leaderboardTop3.length">
              <div class="col-md-12 text-center" v-if="leaderboardTop3[0]">
                <span class="font-size-28"><i class="mdi mdi-crown-outline text-gold"></i></span>
                <router-link title="View Customer" :to="{ path: `/customers/profile/${leaderboardTop3[0].customer_id}`}">
                  <h4>
                    <h4>{{ getTopDisplayName(0) }}</h4>
                  </h4>
                </router-link>

                <p class="text-muted" v-html="formatNickname(leaderboardTop3[0].nickname)"></p>
                <h5>{{ leaderboardTop3[0].score }}</h5>
              </div>
              <div class="col-md-6 text-center" v-if="leaderboardTop3[1]">
                <p class="badge rounded-circle bg-silver font-size-16">{{ leaderboardTop3[1].position }}</p>
                <router-link title="View Customer" :to="{ path: `/customers/profile/${leaderboardTop3[0].customer_id}`}">
                  <h4>
                    <h4>{{ getTopDisplayName(1) }}</h4>
                  </h4>
                </router-link>

                <p class="text-muted" v-html="formatNickname(leaderboardTop3[1].nickname)"></p>
                <h5>{{ leaderboardTop3[1].score }}</h5>
              </div>
              <div class="col-md-6 text-center" v-if="leaderboardTop3[2]">
                <p class="badge rounded-circle font-size-16" :class="[leaderboardTop3[2].score == leaderboardTop3[1].score ? 'bg-silver' : 'bg-bronze']">{{ leaderboardTop3[2].position }}</p>
                <router-link title="View Customer" :to="{ path: `/customers/profile/${leaderboardTop3[0].customer_id}`}">
                  <h4>
                    <h4>{{ getTopDisplayName(2) }}</h4>
                  </h4>
                </router-link>

                <p class="text-muted" v-html="formatNickname(leaderboardTop3[2].nickname)"></p>
                <h5>{{ leaderboardTop3[2].score }}</h5>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12 text-center">
                <p>No data available</p>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="card-body d-flex align-items-center justify-content-center">
              <b-spinner large></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card" v-if="showLeaderTable">
          <div class="card-body">
            <ColumnVisibilityV2 :fields="fields" :visFields="visibleFields"></ColumnVisibilityV2>
            <!-- Table -->
            <div>
              <v-app>
                <v-data-table
                    :headers="visibleFields"
                    :items="leaderboardData"
                    :search="filter"
                    :items-per-page="perPage"
                    :page="currentPage"
                    :server-items-length="totalItems"
                    :hide-default-footer="true"
                    @update:page="onPageChange"
                    @update:items-per-page="onItemsPerPageChange"
                    @update:sort-desc="updateSortDesc"
                    @update:sort-by="updateSortBy"
                >

                  <template v-slot:item.first_name="{ item }">
                    <router-link title="View Customer" :to="{ path: `/customers/profile/${item.customer_id}`}">
                      {{ item.first_name ? (item.first_name + ' ' + item.middle_name + ' ' + item.last_name) : item.email }}
                    </router-link>
                  </template>

                  <template v-slot:footer>
                    <v-row v-if="showLoader" class="mx-4">
                      <v-col class="text-center">
                        <v-progress-linear indeterminate color="primary"></v-progress-linear>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-pagination v-model="currentPage" :length="totalPages" :total-visible="6"
                                      @input="onPageChange" circle></v-pagination>
                      </v-col>
                    </v-row>
                  </template>

                  <template v-slot:top="{ pagination, options, updateOptions }">
                    <v-row>
                      <v-col md="6" sm="12">
                        <v-data-footer :pagination="pagination" :options="options" @update:options="updateOptions"
                                       items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                                       :items-per-page-options="itemsPerPageOpts"/>
                      </v-col>
                      <v-col md="6" sm="12">
                        <v-text-field v-model="filter" :append-icon="filter ? 'mdi-close' : 'mdi-magnify'"
                                      @click:append="clearSearch" label="Search" single-line hide-details outlined
                                      dense></v-text-field>
                      </v-col>
                    </v-row>
                  </template>

                </v-data-table>
              </v-app>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->

    <!-- END  MODALS -->
  </Layout>
</template>
